@import './assets/styles/css/font.css';

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
